<template>
    <div class="loadingWrapper">
        <div class="loadingBox">
            <!--loading.io loader-->
            <div class="loadingio-spinner-pulse-2by998twmg8"><div class="ldio-yzaezf3dcmj"><div></div><div></div><div></div></div></div>
            <!--loading.io END-->
        </div>
    </div>
</template>

<style>
    .loadingWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
    }
    .loadingBox {
        position: relative;
        display: flex;
        height: 400px;
        left: -20px;
        justify-content: center;
        align-items: center;
    }

    /* LOADING.IO LOADER */
    @keyframes ldio-yzaezf3dcmj-1 {
      0% { top: -2.6800000000000193px; height: 273.36000000000007px }
      50% { top: 53.6px; height: 160.8px }
      100% { top: 53.6px; height: 160.8px }
    }
    @keyframes ldio-yzaezf3dcmj-2 {
      0% { top: 11.389999999999981px; height: 245.22000000000006px }
      50% { top: 53.6px; height: 160.8px }
      100% { top: 53.6px; height: 160.8px }
    }
    @keyframes ldio-yzaezf3dcmj-3 {
      0% { top: 25.46px; height: 217.08px }
      50% { top: 53.6px; height: 160.8px }
      100% { top: 53.6px; height: 160.8px }
    }
    .ldio-yzaezf3dcmj div { position: absolute; width: 58.96px }.ldio-yzaezf3dcmj div:nth-child(1) {
      left: 37.52px;
      background: #5f9ba8;
      animation: ldio-yzaezf3dcmj-1 1.3157894736842106s cubic-bezier(0,0.5,0.5,1) infinite;
      animation-delay: -0.2631578947368421s
    }
    .ldio-yzaezf3dcmj div:nth-child(2) {
      left: 104.52000000000001px;
      background: #5cadbc;
      animation: ldio-yzaezf3dcmj-2 1.3157894736842106s cubic-bezier(0,0.5,0.5,1) infinite;
      animation-delay: -0.13157894736842105s
    }
    .ldio-yzaezf3dcmj div:nth-child(3) {
      left: 171.52px;
      background: #a8d5dd;
      animation: ldio-yzaezf3dcmj-3 1.3157894736842106s cubic-bezier(0,0.5,0.5,1) infinite;
      animation-delay: undefineds
    }

    .loadingio-spinner-pulse-2by998twmg8 {
      width: 268px;
      height: 268px;
      display: inline-block;
      overflow: hidden;
      
    }
    .ldio-yzaezf3dcmj {
      width: 100%;
      height: 100%;
      position: relative;
      transform: translateZ(0) scale(1);
      backface-visibility: hidden;
      transform-origin: 0 0; /* see note above */
    }
    .ldio-yzaezf3dcmj div { box-sizing: content-box; }
    /* LOADING.IO END */
</style>