<template>

    <div class="deleteClientButton" @click="Toggle()">[Delete]</div>
    <div class="deleteClientTypeModal" v-if="showPrompt">
        <div class="deleteClientTypeModalContent">
            <h2>Are you sure you want to delete this client type?</h2>
            <button @click="saveChanges()" class="deleteClientTypeButton">Yes</button>
            <button @click="Toggle()" class="deleteClientTypeButton">No</button>
        </div>
    </div>
</template>
<script>
    import axios from 'axios';

    export default {
        name: 'deleteClientType',
        components: {
                 
        }, 
        props: {
            selectedId: {
                type: String,
                required: true
            }
        }, 
        data() {
            return {
                showPrompt : false,
            }
        },
        methods: {   
            Test() {
                console.log(this.selectedId);
            },
            Toggle() {
                this.showPrompt = !this.showPrompt;
            },
            async saveChanges() {
                try {
                const response = await axios.post('https://prod-81.westeurope.logic.azure.com:443/workflows/6c39715378fb48179907daa61d6cfaa7/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=OcNjV45YpptjvqiOi8jPDVFs4cW2B5Aed3cyKndl9eU', {
                    id: this.selectedId,
                });
                    console.log(response.data);
                } catch (error) {
                    console.error('Error fetching client type data:', error);
                }

                this.$emit("ClearSelection");
            }
        }
    }
</script>

<style>
    .deleteClientTypeModal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1000;
    }
    .deleteClientTypeModalContent {
        background-color: white;
        margin: 25% auto;
        padding: 20px;
        border: 1px solid #888;
        border-radius: 10px;
        width: 50%;
        text-align: center;
    }
    .deleteClientTypeButton {
        margin: 10px;
    }
    .deleteClientButton {
        width: 150px;
        height: 34px;
        font-size: 14px;
        margin-bottom: 10px;
        font-weight: bold;
        border: 1px solid red;
        margin-left: 5px;
        margin-right: 5px;
        display: flex;
        justify-content: center;
        border-radius: 5px;
        color: white;
        cursor: pointer;
        background-color: #bd342f;
        align-items: center;
    }
    .deleteClientButton:hover {
        background-color: #d95550;
    }
</style>