<template>
    <div class="fullLocationsScreenWrapper">
        <div class="locationsModal">
            
            <div class="titleRow">
                <div class="titleItem">Locations</div>
                <div class="titleItem titleCloseButton" @click="closeWindow()">X</div>
            </div>
            <loadingAnimation v-if="showLoading" />

            <button v-if="!showLoading" class="locationAddNew" @click="openInsertLocation()">Add new</button>
            <insertLocation v-if="!showLoading && showInsertLocation" @close="closeInsertLocation" />

            <div class="locationsTable">
                <div class="locationsRow" v-for="(line) in locationsData" :key="line.id">
                    <!--Only shown if a type is not selected-->
                    <div class="locationsData" @click="setSelection(line.id,line.Name)" v-if="selectedId != line.id">{{ line.Name }}</div>
                    <!--Only shown if a type is selected-->
                    <input class="editLocationsInput" type="text" v-if="selectedId == line.id" v-model="selectedName">
                    <deleteLocation v-if="selectedId == line.id" :selectedId="line.id" @ClearSelection="clearSelection()" />
                    <button class="editLocationsButton" @click="saveChanges()" v-if="selectedId == line.id">Save Changes</button>
                </div>
                <div class="locationsRow">
                    
                </div>
                
                <!--<button class="editModeButton" @click="saveChanges()" v-if="!showLoading">Save Changes</button>-->
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import loadingAnimation from './loadingAnimation.vue';
    import insertLocation from './insertLocation.vue';
    import deleteLocation from './deleteLocation.vue';

    export default {
        name: 'editLocations',
        components: {
        loadingAnimation,
        insertLocation,
        deleteLocation,
        },  
        data() {
            return {
                locationsData: [],
                showLoading: true,
                showInsertLocation: false,
                selectedName: '',
                selectedId: null
            }
        },
        methods: {
            closeWindow() {
                this.$emit('close');
            },
            setSelection(setId,setName) {
                this.selectedId = setId;
                this.selectedName = setName;
            },
            openInsertLocation() {
                this.showInsertLocation = true;
            },
            closeInsertLocation() {
                this.showInsertLocation = false;
                this.fetchLocations();
            },
            async fetchLocations() {
                try {
                const response = await axios.post('https://prod-97.westeurope.logic.azure.com:443/workflows/4d2387bca17c484186144963e65c6a28/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=6OMunizMoFN6cDWWa2P30Q9ICmUTHfXeIQpw5NV5R0g');
                this.locationsData = response.data;
                this.showLoading = false;
                console.log(this.locationData)
                } catch (error) {
                    console.error('Error fetching Locations data:', error);
                }
            },
            clearSelection() {
                this.selectedId = null;
                this.selectedName = '';
                this.fetchLocations();
            },
            async saveChanges() {
                this.showLoading = true;
                try {
                const response = await axios.post('https://prod-86.westeurope.logic.azure.com:443/workflows/61afd445c1c946fc90379e6f0bbb5513/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=X19124bW_e9kAqyurekEO_Aij658HqYxYR60spd7aGA', {
                    id: this.selectedId,
                    Name: this.selectedName,
                });
                    console.log(response.data);
                } catch (error) {
                    console.error('Error fetching locations data:', error);
                }

                this.clearSelection();
            }
        },mounted() {
            this.fetchLocations();
        },
    }
</script>

<style>
    .fullLocationsScreenWrapper {
        display: flex;
        position: absolute;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 1300px;
        margin: -20px;
        z-index: 1000;

    }
    .locationAddNew {
        width: 100%;
        height: 34px;
        font-size: 14px;
        margin-bottom: 10px;
    }
    .locationsModal {
        position: absolute;
        top: -100px;
        width: 600px;
        min-height: 400px;
        border: 1px solid #000;
        background-color: #efeade;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 20px;
        z-index: 1200;
        opacity: 1.0;
    }
    .editLocationsInput {
        width: 600px;
        height: 32px;
        font-size: 14px;
        border: 0px;
        border-radius: 5px;
        padding-left: 10px;
    }
    .editLocationsButton {
        width: 200px;
        height: 34px;
        font-size: 14px;
        margin-bottom: 10px;
    }
    .titleRow {
        display: flex;
        font-size: 24px;
        justify-content: space-between;
        margin-bottom: 50px;
        
    }
    .titleItem {
        font-weight: bold;
    }
    .titleCloseButton {
        cursor: pointer;
    }
    .locationsTable {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
    .locationsRow {
        display: flex;
        justify-content: start;
        width: 100%;
        margin-bottom: 0px;
    }
    .locationsData {
        display: flex;
        padding: 10px;
        width: 100%;
        cursor: pointer;
        margin-bottom: 0px;
        margin-top: 0px;
        background-color: #5f9ba8;
        padding: 10px;
        color: white;
        border-radius: 5px;
        margin-bottom: 5px;
        border: 0px;
    }
</style>