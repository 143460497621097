<template>
    <loadingAnimation v-if="showLoading" />
    <div class="insertClientTypeAddBar" v-else>
        <input class="insertClientTypeAddInput" type="text" v-model="clientName" />
        <button class="insertClientTypeAddButton" @click="SaveChanges()">Insert Client Type</button>
    </div>
</template>

<script>
import loadingAnimation from './loadingAnimation.vue';
import axios from 'axios';

export default {
        name: 'insertClientType',
        components: {
            loadingAnimation,
         },
        data() {
            return {
                showLoading: false,
                clientName: '',
            }
        },
        methods: {
            async SaveChanges() {
                if (this.clientData != '') {
                    this.showLoading = true;
                    try {
                    const response = await axios.post('https://prod-75.westeurope.logic.azure.com:443/workflows/77b3b766ce294d8f95ea8980b678928b/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=WCtfBpZsyMsUovD9CENf4DCtT7qCg1BkY-CL8siWCBE', {
                        TypeName: this.clientName,
                    });
                        console.log(response.data);
                    } catch (error) {
                        console.error('Error saving client type data:', error);
                    }

                    this.$emit('close');
                }
            }
        }
    };
</script>

<style>
    .insertClientTypeAddBar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
    }
    .insertClientTypeAddButton {
        height: 30px;
        width: 250px;
        font-size: 14px;
    }
    .insertClientTypeAddInput  {
        height: 28px;
        width: 100%;
        margin-right: 10px;
    }
    
</style>