<template>
    <loadingAnimation v-if="showLoading" />
    <div class="insertLocationBar" v-else>
        <input class="insertLocationInput" type="text" v-model="locationName" />
        <button class="insertLocationButton" @click="SaveChanges()">Insert Location</button>
    </div>
</template>

<script>
import loadingAnimation from './loadingAnimation.vue';
import axios from 'axios';

export default {
        name: 'insertLocation',
        components: {
            loadingAnimation,
         },
        data() {
            return {
                showLoading: false,
                locationName: '',
            }
        },
        methods: {
            async SaveChanges() {
                if (this.clientData != '') {
                    this.showLoading = true;
                    try {
                    const response = await axios.post('https://prod-51.westeurope.logic.azure.com:443/workflows/5743626e0831459b827c59c9cb950537/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=o1Kj25ZfcNg5DFNncpMMwM_fdeIMvrhvGUt_UjTT990', {
                        Name: this.locationName,
                    });
                        console.log(response.data);
                    } catch (error) {
                        console.error('Error saving location data:', error);
                    }

                    this.$emit('close');
                }
            }
        }
    };
</script>

<style>
    .insertLocationBar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
    }
    .insertLocationButton {
        height: 30px;
        width: 250px;
        font-size: 14px;
    }
    .insertLocationInput  {
        height: 28px;
        width: 100%;
        margin-right: 10px;
    }
    
</style>