<template>
    <button class="menuButton" @click="openAddNewIP()">Add new IP</button>
    <button class="menuButton" @click="openClientTypes()">Edit Client Types</button>
    <button class="menuButton" @click="openLocations()">Edit Locations</button>
</template>

<script>
    export default {
        methods: {
            openAddNewIP() {
                this.$emit('open-insert-ip');
            },
            openClientTypes() {
                this.$emit('open-client-types');
            },
            openLocations() {
                this.$emit('open-locations');
            }
        }
    }
</script>

<style>
    .menuButton {
        background-color: #43717a;
        border: none;
        color: white;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        height: 40px;
        cursor: pointer;
        border-radius: 5px;
        padding: 10px 24px;
    }
    .menuButton:hover {
        color: black;
    }
    
</style>