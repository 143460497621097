<template>
    <div class="fullScreenWrapper">
        <div class="entryModal">
            <div class="titleRow">
                <div class="titleItem">Entry Details!</div>
                <div class="titleItem titleCloseButton" @click="closeWindow()">X</div>
            </div>
            
            <div v-if="entry">
                <div v-if="editMode">
                    <input type="text" class="editName" v-model="entry.Name" />
                </div>
                <div v-else>
                    <h1>{{entry.Name}}</h1>
                </div>

                <div class="entryRow">
                    <div class="entryTitle">IP Address:</div>
                    <div class="entryData" v-if="editMode">
                        <input type="text" class="editIP" v-model="entry.IP1" /> 
                        <input type="text" class="editIP" v-model="entry.IP2" /> 
                        <input type="text" class="editIP" v-model="entry.IP3" /> 
                        <input type="text" class="editIP" v-model="entry.IP4" />
                    </div>
                    <div class="entryData rowIP" v-else>
                        {{ entry.IP1 }}.{{ entry.IP2 }}.{{ entry.IP3 }}.{{ entry.IP4 }}
                    </div>
                </div>

                <div class="entryRow">
                    <div class="entryTitle">Location</div>
                    <div class="entryData" v-if="editMode">
                        <select v-model="entry.LocationId" class="dropDownMenu">
                            <option v-for="loc in locationId" :key="loc.id" :value="loc.id">{{ loc.Name }}</option>
                        </select>
                    </div>
                    <div class="entryData" v-else>{{ getLocationNameById(entry.LocationId).Name }}</div>
                </div>

                <div class="entryRow">
                    <div class="entryTitle">Client Type:</div>
                    <div class="entryData" v-if="editMode">
                        <select v-model="entry.ClientTypeId" class="dropDownMenu">
                            <option v-for="typ in clientTypeId" :key="typ.id" :value="typ.id">{{ typ.TypeName }}</option>
                        </select>
                    </div>
                    <div class="entryData" v-else>{{ getTypeNameById(entry.ClientTypeId).TypeName }}</div>
                </div>
                <div class="entryTitle">Description:</div>
                <p class="entryDescription" v-if="editMode"><textarea class="editDescription" v-model="entry.Description"></textarea></p>
                <p class="entryDescription" v-else> {{ entry.Description }}</p>

                <searchSharepoint :searchString="entry.Name" />

                <button class="editModeButton" @click="saveChanges()" v-if="editMode">Save Changes</button>
                <button class="editModeButton" @click="toggleEditMode()" v-else>Edit Mode</button>

                <deleteEntry @ClearSelection="closeWindow()" :selectedId="id" />
            </div>
            <div v-else>
            <p>Loading...</p>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import deleteEntry from './deleteEntry.vue';
    import searchSharepoint from './searchSharepoint.vue';

    export default {
    name: 'showEntry',
    props: {
        id: {
            type: Number,
            required: true,
        },
        locationId: {
            type: Number,
            required: true,
        },
        clientTypeId: {
            type: Number,
            required: true,
        },
    },
    components: {
        deleteEntry,
        searchSharepoint             
    },
    data() {
        return {
            entry: null,
            editMode: false,
        };
    },
    mounted() {
        this.fetchEntryData();
    },
    methods: {
        
        async fetchEntryData() {
        try {
            const response = await axios.post('https://prod-21.westeurope.logic.azure.com:443/workflows/9830c814ff5e4ac2a4ea40aaf9ebb78c/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=MFYxQNhXKofLtL5aJRBqvLcBriQu8ZyU2TqhOzvAd50', {
                id: this.id
            });
            this.entry = response.data[0];
        } catch (error) {
            console.error('Error fetching entry data:', error);
        }
        },
        closeWindow() {
            this.$emit('close-entry');
        },
        toggleEditMode() {
            this.editMode = !this.editMode;
        },
        async saveChanges() {
            try {
                const response = await axios.post('https://prod-04.westeurope.logic.azure.com:443/workflows/26e8a58cb3df41a18c7cf3f19c057f52/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=faN9ls3gklMM10G16R_fLmIIj11G5JDwNGZd7rcu2G4', {
                    id: this.id,
                    IP1: parseInt(this.entry.IP1),
                    IP2: parseInt(this.entry.IP2),
                    IP3: this.entry.IP3.toString(),
                    IP4: this.entry.IP4.toString(),
                    ClientTypeId: this.entry.ClientTypeId,
                    LocationId: this.entry.LocationId,
                    Name: this.entry.Name,
                    Description: this.entry.Description
                });
                console.log(response.data);
            } catch (error) {
                console.error('Error fetching entry data:', error);
            }

            this.editMode = false;
        },
        getLocationNameById(id) {
            return this.locationId.find(loc => loc.id === id);
        },
        getTypeNameById(id) {
            return this.clientTypeId.find(loc => loc.id === id);
        }

        }
    };
</script>

<style>
    .fullScreenWrapper {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 1300px;
        margin: -20px;
        z-index: 1000;

    }
    .entryModal {
        position: absolute;
        top: -100px;
        width: 600px;
        min-height: 400px;
        border: 1px solid purple;
        background-color: #efeade;
        border-radius: 10px;
        padding: 20px;
        z-index: 1200;
        opacity: 1.0;
    }
    .editName {
        width: 580px;
        height:40px;
        padding:10px;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
    }
    .entryRow {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .entryIP {
        text-align: center;
        align-content: center;
        width: 30px;
        height: 30px;
        padding: 5px;
        background-color: #5f9ba8;
        color: #ffffff;
        border-radius: 10px;
        margin-left: 5px;
        border: 0px;
    }
    .rowIP {
        display: flex;
    }
    
    .entryTitle {
        font-weight: bold;
        font-size: 20px;
        color: #5f9ba8;
    }
    .entryDescription {
        background-color:  #5f9ba8;
        color: white;
        padding: 10px;
        min-height: 200px;
        border-radius: 10px;
    }
    .titleRow {
        display: flex;
        font-size: 24px;
        justify-content: space-between;
        margin-bottom: 50px;
        
    }
    .titleItem {
        font-weight: bold;
    }
    .titleCloseButton {
        cursor: pointer;
    }
    .editModeButton {
        background-color: #ffffff;
        width: 100%;
        height: 40px;
        font-size: 16px;
        color: #5f9ba8;
        border: 3px solid #5f9ba8;
        border-radius: 5px;
        padding: 10px 20px;
        cursor: pointer;
    }
    .editModeButton:hover {
        background-color: #5f9ba8;
        color: white;
    }
    .editDescription {
        width: 100%;
        height: 200px;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 16px;
        color: #ffffff;
        background-color: #5f9ba8;
        border: 1px dotted yellow;
    }
    .editIP {
        text-align: center;
        width: 30px;
        height: 30px;
        padding: 5px;
        margin-left: 5px;
        background-color: #5f9ba8;
        color: #ffffff;
        border-radius: 10px;
        border: 0px;
    }
    .dropDownMenu {
        background-color: #5f9ba8;
        color: #ffffff;
        width: 200px;
        padding: 10px;
        border-radius: 10px;
        border: 0px;
    }
</style>
  