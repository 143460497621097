<template>
  
      <div id="app">
        <loginPage v-if="!isAuthenticated" @login-success="handleLoginSuccess" />
        <homePage v-else />
      </div>
</template>

<script>
import loginPage from './components/loginPage.vue';
import homePage from './components/homePage.vue';
import { getAccount } from './services/authService';

export default {
  name: 'App',
  components: {
    loginPage,
    homePage,
  },
  data() {
    return {
      isAuthenticated: false,
    };
  },
  mounted() {
    const account = getAccount();
    if (account) {
      this.isAuthenticated = true;
    }
  },
  methods: {
    handleLoginSuccess() {
      this.isAuthenticated = true;
    },
  },
};
</script>

<style>
body {
    margin: 0px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    background-color: #5f9ba8;
  }

</style>
