<template>
    <div class="sharepointContainer">
        <div class="loadingWindow" v-if="loading">
            Searching for documentation in Teams... <img src="../assets/loadingSmall.gif" alt="loading" />
        </div>
        
        <div v-if="dataCount === 0 && !loading">
            No documentation found in Teams.
        </div>

        <div v-else v-for="(obj, index) in responseData" :key="index">
            
            <a :href="obj.url" class="documentationLink"  target="_blank">{{ obj.name }}</a>        
            
        </div>
    </div>
    
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'searchSharepoint',
        props: {
            searchString: {
                type: String,
                required: true,
            }
        },
        components: {
                      
        },
        data() {
            return {
                responseData: [],
                dataCount: 0,
                loading: true,
            };
        },
        mounted() {
            this.fetchEntryData();
        },
        methods: {
            
            async fetchEntryData() {
                try {
                    const response = await axios.post('https://prod-57.westeurope.logic.azure.com:443/workflows/1a3335ff87874469a4bfb4b2dad3e41d/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=OZMf5l9yyMY-28Casyo9piw3X4tjl4Hrq7WiD2QJUBo', {
                        SearchString: this.searchString
                    });
                    this.responseData = response.data;
                    console.log(this.responseData)
                } catch (error) {
                    console.error('Error fetching entry data:', error);
                }
                this.dataCount = this.responseData.length;
                this.loading = false;
            }
        }
    };
</script>

<style>
    .sharepointContainer {
        margin-top: 20px;
        margin-bottom: 20px;
        background-color: white;
        border-radius: 5px;
        padding: 10px;
    }
    .loadingWindow {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
        background-color: white;
        border-radius: 5px;
        padding: 10px;
    }
    .documentationLink {
        display: block;
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 5px;
        padding: 10px;
        text-decoration: none;
        color: white;
        background-color: #5f9ba8;
    }
    .documentationLink:hover {
        background-color: #A8D5DD;
    }
</style>
  