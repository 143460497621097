import * as msal from '@azure/msal-browser';

// Set the Enviroment to DEV or PROD
var Enviroment = 'PROD'
var uriKey = ''
var clientAppID = ''

if (Enviroment == 'DEV') {
  //Dev key
  clientAppID = '2bc74504-94d6-4cf0-95c8-d3f105f6c716'
  uriKey = 'http://localhost:8080'
} else {
  //Prod key
  clientAppID = '7ba317e6-263d-40bf-8a16-a9603ab44d3e'
  uriKey = 'https://ip.cjc.dk/'
}

const msalConfig = {
  auth: {
    clientId: clientAppID,
    authority: 'https://login.microsoftonline.com/5e5310c9-5eb4-4805-97dc-bfcaefcd29ae',
    redirectUri: uriKey, // Ensure this is the correct redirect URI
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

// Ensuring the instance is properly initialized
export const ensureInitialized = async () => {
  try {
    await msalInstance.initialize();
  } catch (error) {
    console.error('Initialization failed', error);
    throw error;
  }
};

export const login = async () => {
  await ensureInitialized();
  const currentAccounts = msalInstance.getAllAccounts();
  if (currentAccounts && currentAccounts.length > 0) {
    console.log('User already logged in');
    return;
  }
  try {
    await msalInstance.loginPopup({
      scopes: ['user.read'],
    });
  } catch (error) {
    console.error('Login failed', error);
    throw error;
  }
};

export const getAccount = () => {
  const currentAccounts = msalInstance.getAllAccounts();
  if (currentAccounts === null || currentAccounts.length === 0) {
    return null;
  }
  return currentAccounts[0];
};
