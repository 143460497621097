<template>
    <div class="fullClientDataScreenWrapper">
        <div class="clientDataModal">
            
            <div class="titleRow">
                <div class="titleItem">Client Types</div>
                <div class="titleItem titleCloseButton" @click="closeWindow()">X</div>
            </div>
            <loadingAnimation v-if="showLoading" />
            <button v-if="!showLoading" class="clientDataAddNew" @click="openInsertClientType()">Add new</button>
            <insertClientType v-if="!showLoading && showInsertClientType" @close="closeInsertClientType" />
            <div class="clientTypeTable">
                <div class="clientTypeRow" v-for="(line) in clientTypeData" :key="line.id">
                    <!--Only shown if a type is not selected-->
                    <div class="clientTypeData" @click="setSelection(line.id,line.TypeName)" v-if="selectedId != line.id">{{ line.TypeName }}</div>
                    <!--Only shown if a type is selected-->
                    <input class="editTypeInput" type="text" v-if="selectedId == line.id" v-model="selectedName">
                    <deleteClientType v-if="selectedId == line.id" :selectedId="line.id" @ClearSelection="clearSelection()" />
                    <button class="editTypeButton" @click="saveChanges()" v-if="selectedId == line.id">Save Changes</button>
                </div>
                <div class="clientTypeRow">
                    
                </div>
                
                <!--<button class="editModeButton" @click="saveChanges()" v-if="!showLoading">Save Changes</button>-->
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import loadingAnimation from './loadingAnimation.vue';
    import insertClientType from './insertClientType.vue';
    import deleteClientType from './deleteClientType.vue';

    export default {
        name: 'editClientTypes',
        components: {
        loadingAnimation,
        insertClientType,
        deleteClientType,
    },  
        data() {
            return {
                clientTypeData: [],
                showLoading: true,
                showInsertClientType: false,
                selectedName: '',
                selectedId: null
            }
        },
        methods: {
            closeWindow() {
                this.$emit('close');
            },
            setSelection(setId,setName) {
                this.selectedId = setId;
                this.selectedName = setName;
            },
            openInsertClientType() {
                this.showInsertClientType = true;
            },
            closeInsertClientType() {
                this.showInsertClientType = false;
                this.fetchClientTypes();
            },
            clearSelection() {
                this.selectedId = null;
                this.selectedName = '';
                this.fetchClientTypes();
            },
            async fetchClientTypes() {
                try {
                const response = await axios.post('https://prod-194.westeurope.logic.azure.com:443/workflows/d95f22440f984cc7963601e320f326f1/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=NVPkfrGhpdpDLxT-ECyczxWMfS71tAn7O2mkD388OnM');
                this.clientTypeData = response.data;
                this.showLoading = false;
                } catch (error) {
                    console.error('Error fetching Client type data:', error);
                }
            },
            async saveChanges() {
                this.showLoading = true;
                try {
                const response = await axios.post('https://prod-78.westeurope.logic.azure.com:443/workflows/aa3f349f5fe24d2ca005da58b78a4adf/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=61qGUnU3ocHxM-qeMSG1f826h27pzjN9g9nzmei-MwE', {
                    id: this.selectedId,
                    TypeName: this.selectedName,
                });
                    console.log(response.data);
                } catch (error) {
                    console.error('Error fetching client type data:', error);
                }

                this.clearSelection();
                
            }
        },mounted() {
            this.fetchClientTypes();
        },
    }
</script>

<style>
    .fullClientDataScreenWrapper {
        display: flex;
        position: absolute;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 1300px;
        margin: -20px;
        z-index: 1000;

    }
    .clientDataAddNew {
        width: 100%;
        height: 34px;
        font-size: 14px;
        margin-bottom: 10px;
    }
    
    .clientDataModal {
        position: absolute;
        top: -100px;
        width: 600px;
        min-height: 400px;
        border: 1px solid #000;
        background-color: #efeade;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 20px;
        z-index: 1200;
        opacity: 1.0;
    }
    .editTypeInput {
        width: 600px;
        height: 32px;
        font-size: 14px;
        border: 0px;
        border-radius: 5px;
        padding-left: 10px;
    }
    .editTypeButton {
        width: 200px;
        height: 34px;
        font-size: 14px;
        margin-bottom: 10px;
    }
    .titleRow {
        display: flex;
        font-size: 24px;
        justify-content: space-between;
        margin-bottom: 50px;
        
    }
    .titleItem {
        font-weight: bold;
    }
    .titleCloseButton {
        cursor: pointer;
    }
    .clientTypeTable {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
    .clientTypeRow {
        display: flex;
        justify-content: start;
        width: 100%;
        margin-bottom: 0px;
    }
    .clientTypeData {
        display: flex;
        padding: 10px;
        width: 100%;
        cursor: pointer;
        margin-bottom: 0px;
        margin-top: 0px;
        background-color: #5f9ba8;
        padding: 10px;
        color: white;
        border-radius: 5px;
        margin-bottom: 5px;
        border: 0px;
    }
</style>