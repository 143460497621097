<template>
  <div class="fullScreenWrapper1">
      <div class="loginWrapper">
        <div class="topImage">
          <img src="../assets/bg.png" alt="logo" />
        </div>
        <div class="loginBox">
          <button @click="login">Login with Microsoft</button>
        </div>
      </div>
  </div>
</template>

<script>
import { login, getAccount } from '../services/authService';

export default {
  name: 'loginPage',
  methods: {
    async login() {
      try {
        await login();
        const account = getAccount();
        if (account) {
          this.$emit('login-success');
        }
      } catch (error) {
        console.error('Login failed', error);
      }
    },
  },
};
</script>





  <style>
    .fullScreenWrapper1 {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      width: 100vw;

    }
    .loginWrapper {
      position: relative;
      display: flex;      
      height: 400px;
      left: -20px;
      justify-content: center;
      align-items: center;
    }
    .topImage {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
    }
    .loginBox {
      background-color: #f5f5f5;
      width: 400px;
      height: 200px;
      border-radius: 5px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    button {
      background-color: #5f9ba8;
      width: 300px;
      height: 100px;
      font-size: 24px;
      color: #fff;
      border: none;
      border-radius: 5px;
      padding: 10px 20px;
      cursor: pointer;
    }
    button:hover {
      background-color: #cac2af;
    }

    
  </style>
  