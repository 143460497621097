<template>
  <div class="fullScreenCenter">
    <div class="mainContent">
      <div class="banner">
        <img src="../assets/bg.png" alt="logo" />
        <menuBar @open-client-types="openEditClientTypes()" @open-insert-ip="openInsertIP()" @open-locations="openEditLocations()" />
      </div>
      <div class="contentHolder">
        <insertIP v-if="showInsertIP" @close="closeInsertIP()" />
        <editClientTypes v-if="showEditClientTypes" @close="closeEditClientTypes()" />
        <editLocations v-if="showEditLocations" @close="closeEditLocations()" />
        <ipTable />
      </div>
    </div>
  </div>
</template>
  
<script>
  import menuBar from './menuBar.vue'
  import ipTable from './ipTable.vue'
  import insertIP from './insertIP.vue'
  import editClientTypes from './editClientTypes.vue'
  import editLocations from './editLocations.vue'
  export default {
    name: 'homePage',
    components: {
      ipTable,
      menuBar,
      editClientTypes,
      editLocations,
      insertIP,
    },
    data() {
      return {
        showEditClientTypes: false,
        showEditLocations: false,
        showInsertIP: false,
      };
    },
    methods: {
      closeEditClientTypes() {
        this.showEditClientTypes = false;
      },
      openEditClientTypes() {
        this.showEditClientTypes = true;
      },
      closeEditLocations() {
        this.showEditLocations = false;
      },
      openEditLocations() {
        this.showEditLocations = true;
      },
      closeInsertIP() {
        this.showInsertIP = false;
      },
      openInsertIP() {
        this.showInsertIP = true;
      },
    },
  };
</script>
<style>
    .fullScreenCenter {
    display: flex;
    background-color: #5f9ba8;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
  }
  .banner {
    width: 1300px;
    height: 277px;
    display: flex;
    justify-content: space-between;
    align-content: end;
    align-items: end;
  }
  .mainContent {
    width: 1300px;
    min-height: 100vh;   
  }
  .contentHolder {
    position: relative;
    padding: 20px;
    min-height: 100%;
    background-color: white;
    border-radius: 10px;
  }
</style>