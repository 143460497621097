<template>
    <showEntry v-if="selectedEntry" :id="selectedEntry" :locationId="location" :clientTypeId="type" @close-entry="selectedEntry = null" />

    <loadingAnimation v-if="showLoading" />
    <div class="ipTable" v-else>
      <!-- Strict Boxes -->
      <div class="ipRow ipHeader">
        <div class="ipCell ipHeader ipOctet ipFlavor">
          <input type="checkbox" v-model="strictFilters.IP1" />
        </div>
        <div class="ipCell ipHeader ipOctet">
          <input type="checkbox" v-model="strictFilters.IP2" />
        </div>
        <div class="ipCell ipHeader ipOctet ipFlavor">
          <input type="checkbox" v-model="strictFilters.IP3" />
        </div>
        <div class="ipCell ipHeader ipOctet">
          <input type="checkbox" v-model="strictFilters.IP4" />
        </div>
        <div class="ipCell ipHeader ipName ipFlavor">
          <input type="checkbox" v-model="strictFilters.Name" />
        </div>
        <div class="ipCell ipHeader ipDescription">
          <input type="checkbox" v-model="strictFilters.Description" />
        </div>
        <div class="ipCell ipHeader ipClientType  ipFlavor">
          <input type="checkbox" v-model="strictFilters.ClientType" />
        </div>
        <div class="ipCell ipHeader ipLocation">
          <input type="checkbox" v-model="strictFilters.Location" />
        </div>
      </div>
      <!-- Headers -->
      <div class="ipRow ipHeader">
        <div class="ipCell ipHeader ipSortHeader ipOctet ipFlavor" @click="sortData('IP1')">IP 1</div>
        <div class="ipCell ipHeader ipSortHeader ipOctet" @click="sortData('IP2')">IP 2</div>
        <div class="ipCell ipHeader ipSortHeader ipOctet ipFlavor" @click="sortData('IP3')">IP 3</div>
        <div class="ipCell ipHeader ipSortHeader ipOctet" @click="sortData('IP4')">IP 4</div>
        <div class="ipCell ipHeader ipSortHeader ipName ipFlavor" @click="sortData('Name')">Name</div>
        <div class="ipCell ipHeader ipSortHeader ipDescription" @click="sortData('Description')">Description</div>
        <div class="ipCell ipHeader ipSortHeader ipClientType ipFlavor" @click="sortData('ClientType')">Client Type</div>
        <div class="ipCell ipHeader ipSortHeader ipLocation" @click="sortData('Location')">Location</div>
      </div>
      <!-- Search Headers -->
      <div class="ipRow ipHeader">
        <div class="ipCell ipHeader ipOctet ipFlavor">
          <input type="text" class="searchBox" v-model="filters.IP1" placeholder="Search IP 1" />
        </div>
        <div class="ipCell ipHeader ipOctet">
          <input type="text" class="searchBox" v-model="filters.IP2" placeholder="Search IP 2" />
        </div>
        <div class="ipCell ipHeader ipOctet ipFlavor">
          <input type="text" class="searchBox" v-model="filters.IP3" placeholder="Search IP 3" />
        </div>
        <div class="ipCell ipHeader ipOctet">
          <input type="text" class="searchBox" v-model="filters.IP4" placeholder="Search IP 4" />
        </div>
        <div class="ipCell ipHeader ipName ipFlavor">
          <input type="text" class="searchBox" v-model="filters.Name" placeholder="Search Name" />
        </div>
        <div class="ipCell ipHeader ipDescription">
          <input type="text" class="searchBox" v-model="filters.Description" placeholder="Search Description" />
        </div>
        <div class="ipCell ipHeader ipClientType  ipFlavor">
          <input type="text" class="searchBox" v-model="filters.ClientType" placeholder="Search Client Type" />
        </div>
        <div class="ipCell ipHeader ipLocation">
          <input type="text" class="searchBox" v-model="filters.Location" placeholder="Search Location" />
        </div>
      </div>
      <!-- Rows -->
      <div v-for="(row, index) in sortedIpData" :key="index" class="ipRow" @click="handleRowClick(row.id)">
          <div class="ipCell ipOctet ipFlavor">{{ row.IP1 }}</div>
          <div class="ipCell ipOctet">{{ row.IP2 }}</div>
          <div class="ipCell ipOctet ipFlavor">{{ row.IP3 }}</div>
          <div class="ipCell ipOctet">{{ row.IP4 }}</div>
          <div class="ipCell ipName ipFlavor">{{ row.Name }}</div>
          <div class="ipCell ipDescription">{{ row.Description }}</div>
          <div class="ipCell ipClientType  ipFlavor">{{ row.ClientType }}</div>
          <div class="ipCell ipLocation">{{ row.Location }}</div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
import showEntry from './showEntry.vue';
import loadingAnimation from './loadingAnimation.vue';

export default {
  name: 'homePage',
  components: {
    showEntry,
    loadingAnimation
  },
  data() {
    return {
      showLoading: true,
      ipData: [],
      clientTypeData: [],
      locationData: [],
      type: [],
      location: [],
      selectedEntry: null,
      filters: {
        IP1: '',
        IP2: '',
        IP3: '',
        IP4: '',
        Name: '',
        Description: '',
        ClientType: '',   // New filter for Client Type
        Location: ''      // New filter for Location
      },
      strictFilters: {
        IP1: true,
        IP2: true,
        IP3: true,
        IP4: false,
        Name: false,
        Description: false,
        ClientType: false, // New strict filter for Client Type
        Location: false    // New strict filter for Location
      },
      sortColumn: '',
      sortOrder: 'asc',
      sortedData: []
    };
  },
  mounted() {
    this.loadData();

    console.log(this.ipData);
  },
  methods: {
    getLocationNameById(id) {
        return this.location.find(loc => loc.id === id);
    },
    getTypeNameById(id) {
        return this.type.find(loc => loc.id === id);
    },
    async fetchIpData() {
      try {
        const response = await axios.post('https://prod-76.westeurope.logic.azure.com:443/workflows/9e460f417e8a4d8a9dd8532cf55bd1df/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=2vk14ynuleoyTQfT6Vh0yLayV1edQMqhYUh9Z4PV5Mk');
        this.ipData = response.data;
        this.sortData(this.sortColumn); // Sort data after fetching

        // add names to ClientType and Location
        this.ipData.forEach(row => {
          row.ClientType = this.getTypeNameById(row.ClientTypeId).TypeName;
          row.Location = this.getLocationNameById(row.LocationId).Name;
          
        });

        this.showLoading = false;
      } catch (error) {
        console.error('Error fetching IP data:', error);
      }
    },
    async fetchLocationData() {
      try {
        const response = await axios.post('https://prod-97.westeurope.logic.azure.com:443/workflows/4d2387bca17c484186144963e65c6a28/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=6OMunizMoFN6cDWWa2P30Q9ICmUTHfXeIQpw5NV5R0g', {});
        this.location = response.data;
      } catch (error) {
        console.error('Error fetching entry data:', error);
      }
    },
    async fetchClientTypeData() {
      try {
        const response = await axios.post('https://prod-194.westeurope.logic.azure.com:443/workflows/d95f22440f984cc7963601e320f326f1/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=NVPkfrGhpdpDLxT-ECyczxWMfS71tAn7O2mkD388OnM', {});
        this.type = response.data;
      } catch (error) {
        console.error('Error fetching Client data:', error);
      }
    },
    async loadData() {
      
      await this.fetchLocationData();
      await this.fetchClientTypeData();

      this.fetchIpData();
    },
    
    sortData(column) {
      if (this.sortColumn === column) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortColumn = column;
        this.sortOrder = 'asc';
      }
      this.sortedData = [...this.filteredIpData].sort((a, b) => {
        const modifier = this.sortOrder === 'asc' ? 1 : -1;
        if (a[this.sortColumn] < b[this.sortColumn]) return -1 * modifier;
        if (a[this.sortColumn] > b[this.sortColumn]) return 1 * modifier;
        return 0;
      });
    },
    handleRowClick(id) {
      this.selectedEntry = id;
      console.log("Click", id);
    }
  },
  computed: {
    filteredIpData() {
      return this.ipData.filter(row => {
        return (
          (!this.filters.IP1 || (this.strictFilters.IP1 ? row.IP1.toString() === this.filters.IP1 : row.IP1.toString().includes(this.filters.IP1))) &&
          (!this.filters.IP2 || (this.strictFilters.IP2 ? row.IP2.toString() === this.filters.IP2 : row.IP2.toString().includes(this.filters.IP2))) &&
          (!this.filters.IP3 || (this.strictFilters.IP3 ? row.IP3.toString() === this.filters.IP3 : row.IP3.toString().includes(this.filters.IP3))) &&
          (!this.filters.IP4 || (this.strictFilters.IP4 ? row.IP4.toString() === this.filters.IP4 : row.IP4.toString().includes(this.filters.IP4))) &&
          (!this.filters.Name || (this.strictFilters.Name ? row.Name.toLowerCase() === this.filters.Name.toLowerCase() : row.Name.toLowerCase().includes(this.filters.Name.toLowerCase()))) &&
          (!this.filters.Description || (this.strictFilters.Description ? row.Description.toLowerCase() === this.filters.Description.toLowerCase() : row.Description.toLowerCase().includes(this.filters.Description.toLowerCase()))) &&
          (!this.filters.ClientType || (this.strictFilters.ClientType ? row.ClientType.toLowerCase() === this.filters.ClientType.toLowerCase() : row.ClientType.toLowerCase().includes(this.filters.ClientType.toLowerCase()))) &&
          (!this.filters.Location || (this.strictFilters.Location ? row.Location.toLowerCase() === this.filters.Location.toLowerCase() : row.Location.toLowerCase().includes(this.filters.Location.toLowerCase())))
        );
      });
    },
    sortedIpData() {
      return this.sortedData.length ? this.sortedData : this.filteredIpData;
    }
  },
  watch: {
    filters: {
      handler() {
        this.sortData(this.sortColumn); // Re-sort data when filters change
      },
      deep: true
    }
  }
};

  </script>
  
  <style>
  :root {
    --pageWidth: 1300px;
    --pagePadding: 20px;
  }
  .contentHolder {
    position: relative;
    padding: var(--pagePadding);
    background-color: white;
  }
  /* Ip table flex box */
  .ipTable {
    position: relative;
    width: calc(var(--pageWidth) - calc(var(--pagePadding) * 2));

  }
  .ipRow {
    display: flex;
    justify-content: space-evenly;
    border: 1px solid #5f9ba8;
    width: 100%;
    margin-bottom: 5px;
  }
  .ipCell {
    display: flex;
    padding: 10px;
    width: 300px;
    cursor: pointer; /* Change cursor to indicate clickable headers */
  }
  .ipHeader {
    font-weight: bold;
    border: 0px;
  }
  .ipSortHeader:hover {
    font-weight: bold;
    background-color: #5f9ba8;
    color: white;
    border: 0px;
  }
  .ipName {
    width: 300px;
  }
  .ipFlavor {
    background-color: #efeade;
    border-radius: 5px;
  }
  .ipDescription {
    width: 800px;
  }
  .ipOctet {
    justify-content: center;
    width: 50px;
  }
  .searchBox {
    width: 100%;
  }
  </style>
  