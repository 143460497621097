<template>
    <div class="fullLocationsScreenWrapper">
        <div class="locationsModal">
            <div class="titleRow">
                <div class="titleItem">Add a new IP</div>
                <div class="titleItem titleCloseButton" @click="closeWindow()">X</div>
            </div>
            <loadingAnimation v-if="showLoading" />
            <div class="addIpTable" v-else>
                <div class="addIpRow">
                    <div class="addIpCell">IP 1</div>
                    <div class="addIpCell">IP 2</div>
                    <div class="addIpCell">IP 3</div>
                    <div class="addIpCell">IP 4</div>
                    <div class="addIpCell addIpName">Name</div>
                </div>
                <div class="addIpRow">
                    <input class="addIpCell addIpCellInput" type="text" v-model="IP1" />
                    <input class="addIpCell addIpCellInput" type="text" v-model="IP2" />
                    <input class="addIpCell addIpCellInput" type="text" v-model="IP3" />
                    <input class="addIpCell addIpCellInput" type="text" v-model="IP4" />
                    <input class="addIpCell addIpCellInput  addIpName" type="text" v-model="Name" />
                </div>
                <div class="addIpRow">
                    <div class="addIpCell addIpCellDropDownHeader">Client Type</div>
                    <div class="addIpCell addIpCellDropDownHeader">Location</div>
                </div>
                <div class="addIpRow">
                    <select class="addIpCell addIpCellDropDown" v-model="ClientTypeId">
                        <option v-for="line in clientTypeData" :key="line.id" :value="line.id">{{ line.TypeName }}</option>
                    </select>
                    <select class="addIpCell addIpCellDropDown" v-model="LocationId">
                        <option v-for="line in locationsData" :key="line.id" :value="line.id">{{ line.Name }}</option>
                    </select>
                </div>
                
                <div class="addIpCell addIpCellText">Description</div>
                <textarea class="addIpTextArea" v-model="Description" />
            </div>
            <button class="addIpButton" @click="saveChanges()">Save</button>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import loadingAnimation from './loadingAnimation.vue';


    export default {
        name: 'InsertIP',
        components: {

        loadingAnimation,
        },  
        data() {
            return {
                showLoading: true,
                clientTypeData: [],
                locationsData: [],
                IP1: null,
                IP2: null,
                IP3: '',
                IP4: '',
                ClientTypeId: null,
                LocationId: null,
                Name: '',
                Description: '',
            }
        },
        methods: {
            closeWindow() {
                this.$emit('close');
            },
            async saveChanges() {
                this.showLoading = true;
                try {
                const response = await axios.post('https://prod-136.westeurope.logic.azure.com:443/workflows/16afd1458da94331b7bba58b28f4766a/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=kmUMBWO8nky7KWdCz5Xfn_cCFlNYzS5AJFP3shtAsgA', {
                    IP1: Number(this.IP1),
                    IP2: Number(this.IP2),
                    IP3: this.IP3,
                    IP4: this.IP4,
                    ClientTypeId: this.ClientTypeId,
                    LocationId: this.LocationId,
                    Name: this.Name,
                    Description: this.Description
                });
                    console.log(response.data);
                } catch (error) {
                    console.error('Error inserting IP:', error);
                }
                
                this.$emit('close');
                
            },async fetchClientTypes() {
                try {
                    const response = await axios.post('https://prod-194.westeurope.logic.azure.com:443/workflows/d95f22440f984cc7963601e320f326f1/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=NVPkfrGhpdpDLxT-ECyczxWMfS71tAn7O2mkD388OnM');
                    this.clientTypeData = response.data;
                } catch (error) {
                    console.error('Error fetching Client type data:', error);
                }
            },
            async fetchLocations() {
                try {
                    const response = await axios.post('https://prod-97.westeurope.logic.azure.com:443/workflows/4d2387bca17c484186144963e65c6a28/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=6OMunizMoFN6cDWWa2P30Q9ICmUTHfXeIQpw5NV5R0g');
                    this.locationsData = response.data;
                    this.showLoading = false;
                } catch (error) {
                    console.error('Error fetching Locations data:', error);
                }
            },
            loadData() {
                this.fetchClientTypes();
                this.fetchLocations();
                this.showLoading = false;
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>

<style>
    .fullLocationsScreenWrapper {
        display: flex;
        position: absolute;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 1300px;
        margin: -20px;
        z-index: 1000;

    }
    .locationsModal {
        position: absolute;
        top: -100px;
        width: 600px;
        min-height: 400px;
        border: 1px solid #000;
        background-color: #efeade;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 20px;
        z-index: 1200;
        opacity: 1.0;
    }
    .titleRow {
        display: flex;
        font-size: 24px;
        justify-content: space-between;
        margin-bottom: 50px;
        
    }
    .titleItem {
        font-weight: bold;
    }
    .titleCloseButton {
        cursor: pointer;
    }
    .addIpTable {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .addIpRow {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .addIpCell {
        width: 30px;
        margin:5px;
        background-color: #5f9ba8;
        border: 1px solid white;
        padding: 10px;
        border-radius: 5px;
        color: white;
        font-weight: bold;
    }
    .addIpCellDropDown {
        width: 300px;
        background-color: white;
        color: black;
    }
    .addIpCellDropDownHeader {
        width: 300px;
    }
    .addIpCellInput {
        background-color: white;
        color: black;
    }
    .addIpName {
        width: 300px;
    }
    .addIpCellText {
        width: calc(100% - 25px);
        margin-left: 0px;
        background-color: #5f9ba8;
        border: 1px solid white;
        padding: 10px;
        border-radius: 5px;
        color: white;
        font-weight: bold;
    }
    .addIpTextArea {
        width: calc(100% - 25px);
        margin-left: 0px;
        background-color: white;
        border: 1px solid white;
        padding: 10px;
        border-radius: 5px;
        height: 200px;
        color: black;
    }
    .addIpButton {
        width: 100%;
        margin-top: 20px;
    }
</style>